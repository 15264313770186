import React from 'react'
import SiteLayout from '../components/SiteLayout'
import { Row } from 'antd'

import VideoIntegration from '../images/tutorial_show_widget.ogv'
import BankDefaultBank from '../images/tutorial-default-bank.png'
import BudgetsIntegrated from '../images/tutorial-bank-with-widget.png'

const BudgetIngration = ({ location }) => (
	<SiteLayout location={location}>
		<Row>

			<h1>Take a look at how easy it is to integrate the Budgeting module in your Online Banking Platform</h1>

			<div>
				<p>The first thing you’ll want to do when testing out the Strands suite is to display an out-of-the-box widget, which will be integrated into your online banking platform.</p>

				<p>Let's suppose you have an online banking system like this:</p>
				<Row justify="center" style={{ width: "100%", textAlign: "center" }}>
					<img src={BankDefaultBank} style={{ width: "50%", marginTop: "20px", marginBottom: "20px" }} alt="Example bank layout" />
				</Row>
			</div>

			<p>You may want to fill some of those placeholders with information that the user cares about, like the status of their manually created budgets. To do so, we'll use the budgetsoverview widget from Strands FM, which will display an overview of the user's budgets.
            	Start by placing the povided <code>fm.min.js</code> and <code>fm.min.css</code> files from the Strands UI Kit somewhere in your HTTP server. In our example, we'll suppose you place them under the <code>/assets/</code> directory.
				In the &lt;head&gt; tag of your homepage’s HTML, add the following lines to include the files from Strands FM UI: </p>

			<code className="tutorials">
				&lt;link rel="stylesheet" href="assets/bfm.min.css" type="text/css"&gt;<br></br>
				&lt;script src="assets/strands-vendor.min.js"&gt;&lt;/script&gt;<br></br>
				&lt;script src="assets/bfm.min.js"&gt;&lt;/script&gt;
			</code>

			<p>Once you reload the page, you should have a strands object created in your window, which will provide all the neccesary methods to interact with he FM widgets. First of all, we'll create a <code>&lt;script&gt;</code> tag at the end of your <code>&lt;body&gt;</code> tag and will setup the context path of the Strands FM API:</p>

			<code className="tutorials">
				(function(){'{\n'}<br></br>
				strands.bfm.setContextcodeath("http://localhost/api");<br></br>
				{'\n}'})();
			</code>

			<p>This context path will be dependent on your FM installation. In case of doubt, ask your IT team for the Strands FM API url.</p>

			<div>
				<h2>Use of proxies</h2>
				<p>
					In a real environment, you wouldn't hit the Strands FM API directly but a proxy, which will later redirect the requests to Strands FM API if all security checks are validated.
					As a last step, use the <code>bfm.show(widget_name, placeholder_id)</code> method to display the Budgets Overview widget in your placeholder:
				</p>
			</div>

			<code className="tutorials">
				(function(){'{\n'}<br></br>
				strands.bfm.setContextPath("http://localhost/api");<br></br>
				strands.bfm.show("budgetsoverview", "widget-budgets-mini");<br></br>
				{'\n}'})();
			</code>

			<p>If everything went OK, you should now see the status of your budgets in your placeholder #widget-budgets-mini:</p>


			<Row justify="center" style={{ width: "100%", textAlign: "center" }}>
				<img src={BudgetsIntegrated} style={{ width: "50%", marginTop: "20px", marginBottom: "20px" }}  alt="Example Budget widget already integrated" />
			</Row>

			<div>
				<h2>Video</h2>
				<p>You can follow the full tutorial in the next video:</p>
				<Row justify="center" style={{ width: "100%", textAlign: "center" }}>
				<video controls src={VideoIntegration} style={{ width: "50%", marginTop: "20px", marginBottom: "20px" }}>
					Sorry, your browser doesn't support embedded videos.
                 </video>
				 </Row>
			</div>

			<Row justify="center" style={{ width: "100%", textAlign: "center" }}>
				<h2 className="small">Get to know more!</h2>
			</Row>
			<Row justify="center" style={{ width: "100%" }}>
				<p style={{ textAlign: 'center' }}><a href="/become-partner" className="btn">Become a Partner</a></p>
			</Row>

		</Row>
	</SiteLayout>
)

export default BudgetIngration
